import React from "react"
import { Link } from "gatsby"
import InquiryForm from "../components/buy-equipment/inquiry-form"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const EmailSignUp = () => (
  <Layout>
    <SEO title="Email sign up" />
    <div className="row column">
      <InquiryForm
        title={`Sign up for email notifications`}
        formSource={`Email`}
      />
    </div>
  </Layout>
)

export default EmailSignUp
